@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.animated-cards-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		display: flex;
		gap: var(--wp--preset--spacing--60);
		flex-wrap: wrap;

		@media only screen and (max-width: bp.$breakpoint-tiny) {
			gap: 0;
		}

		.animated-cards-block__content {
			flex: 0 1 25%;

			@media only screen and (max-width: bp.$breakpoint-small) {
				flex: 1 1 25%;
				margin-bottom: var(--wp--preset--spacing--40);
			}
		}

		.animated-cards-block__list {
			flex: 1 1 400px;
			@include mixins.grid(250px, auto-fill);
		}
	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	&.dark {

		@include mixins.SVGDarkBg;
		
	}


}